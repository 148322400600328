import './Advantages.scss'

import {useTranslation} from "react-i18next";
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

export const Advantages = () => {
    const {t} = useTranslation()
    const titleRef = useRef<HTMLHeadingElement>(null)
    const isVisibleTitle = useOnScreen(titleRef)

    return <section className={`advantages-sec G-animation ${isVisibleTitle ? 'G-show-element' : ''}`} ref={titleRef}>
        <div className="G-container">
            <h2>{t('advantages-title')}</h2>
            <div className="advantages-text">
              <ul>
                <li>
                  <h3>{t('advantages-title-1')}</h3>
                  <p>{t('advantages-description-1')}</p>
                </li>
                <li>
                  <h3>{t('advantages-title-2')}</h3>
                  <p>{t('advantages-description-2')}</p>
                </li>
                <li>
                  <h3>{t('advantages-title-3')}</h3>
                  <p>{t('advantages-description-3')}</p>
                </li>
                <li>
                  <h3>{t('advantages-title-4')}</h3>
                  <p>{t('advantages-description-4')}</p>
                </li>
                <li>
                  <h3>{t('advantages-title-5')}</h3>
                  <p>{t('advantages-description-5')}</p>
                </li>
              </ul>
            </div>
        </div>
    </section>
}
