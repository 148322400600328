import './Charity.scss'
import {useTranslation} from "react-i18next";


import {Swiper, SwiperProps, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import {Autoplay, EffectFade} from "swiper/modules";
import CharityPhoto from "../../assets/images/charity1.jpg";
import CharityPhoto2 from "../../assets/images/charity2.jpg";
import CharityPhoto3 from "../../assets/images/charity3.jpg";
import CharityPhoto4 from "../../assets/images/charity4.jpeg";
import CharityPhoto5 from "../../assets/images/charity5.jpeg";
import CharityPhoto6 from "../../assets/images/charity6.jpeg";
import CharityPhoto7 from "../../assets/images/charity7.jpeg";
import CharityPhoto8 from "../../assets/images/charity8.jpeg";
import CharityPhoto9 from "../../assets/images/charity9.jpeg";
import CharityPhoto10 from "../../assets/images/charity10.jpeg";

export const Charity = () => {

    const {t} = useTranslation()


    const charity: SwiperProps = {
        loop: true,
        spaceBetween:20,
        slidesPerView: 1,
        autoplay: {
            delay: 1000,
        },
        speed:3000


    }


    return <section className="charity-sec">
        <div className="G-container">
            <div className="charity-cnt">
                 <div className="charity-info">
                <h2>{t('charity-title')}</h2>
                <p>{t('charity-text')}</p>
                <p>{t('charity-text-1')}</p>
                <p>{t('charity-text-2')}</p>
            </div>
                <div className="charity-gallery">
                    <Swiper {...charity} modules={[Autoplay, EffectFade]} navigation={false} className="charity-slider">
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img loading={"lazy"} src={CharityPhoto} alt="charity"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img loading={"lazy"} src={CharityPhoto2} alt="charity"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img loading={"lazy"} src={CharityPhoto3} alt="charity"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img  loading={"lazy"} src={CharityPhoto4} alt="charity"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img loading={"lazy"} src={CharityPhoto5} alt="charity"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img loading={"lazy"} src={CharityPhoto6} alt="charity"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img loading={"lazy"} src={CharityPhoto7} alt="charity"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img loading={"lazy"} src={CharityPhoto8} alt="charity"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img loading={"lazy"} src={CharityPhoto9} alt="charity"/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="charity-photo">
                                <img loading={"lazy"} src={CharityPhoto10} alt="charity"/>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    </section>
}
