import './Company.scss'
import {useTranslation} from "react-i18next";
import CompanyPhoto from '../../assets/images/international.jpg';
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

export const Company = () => {

    const {t} = useTranslation()
    const titleRef = useRef<HTMLHeadingElement>(null)
    const isVisibleTitle = useOnScreen(titleRef)

    return <section ref={titleRef} className={`company-sec G-animation ${isVisibleTitle ? 'G-show-element' : ''}`} id='about' >
        <div className="G-container">
            <div className="global-title">
                <h2>{t('company-name')}</h2>
            </div>
            <div className="company-cnt G-flex G-align-center G-justify-between">
                <div className="company-photo">
                    <img  loading={"lazy"} src={CompanyPhoto} alt="company"/>
                </div>
                <div className="company-info">
                    <h2>{t('company-title')}</h2>
                    <p>{t('company-text')}</p>
                </div>
            </div>
        </div>
    </section>
}
