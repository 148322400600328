import React from 'react';
import {Header} from "./components/Header/Header";
import {Banner} from "./components/Banner/Banner";
import {About} from "./components/About/About";
import {Company} from "./components/Company/Company";
import {Works} from "./components/Works/Works";
import {Footer} from "./components/Footer/Footer";
import {Partners} from "./components/Partners/Partners";
import {Gallery} from "./components/Gallery/Gallery";
import {Advantages} from "./components/Advantages/Advantages";
import {Charity} from "./components/Charity/Charity";


function App() {
    return (
        <>
            <Header/>
            <Banner/>
            <Company/>
            <About/>
            <Advantages/>
            <Works/>
            <Charity/>
            <Partners/>
            <Gallery/>
            <Footer/>
        </>
    );
}

export default App;
