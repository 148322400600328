import './About.scss';
import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ICardWork} from "../../models/works.model";
import {CardWork} from "../CardWork/CardWork";
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import {Autoplay, Navigation} from "swiper/modules";

import {IconArrowLeft} from "../../assets/icons/IconArrowLeft";
import {IconArrowRight} from "../../assets/icons/IconArrowRight";
import useOnScreen from "../../hooks/useOnScreen";


// Projects images
import Project_1_1 from '../../assets/images/projects/project-1-1.jpeg'
import Project_1_2 from '../../assets/images/projects/project-1-2.jpeg'

import Project_2_1 from '../../assets/images/projects/project-2-1.webp'
import Project_2_2 from '../../assets/images/projects/project-2-2.jpg'
import Project_2_3 from '../../assets/images/projects/project-2-3.jpg'


import Project_3_1 from '../../assets/images/projects/project-3-1.jpeg'
import Project_3_2 from '../../assets/images/projects/project-3-2.jpeg'

import Project_4_1 from '../../assets/images/projects/project-4-1.jpeg'
import Project_4_2 from '../../assets/images/projects/project-4-2.jpeg'
import Project_4_3 from '../../assets/images/projects/project-4-3.jpeg'


import Project_5_1 from '../../assets/images/projects/project-5-1.jpeg'
import Project_5_2 from '../../assets/images/projects/project-5-2.jpeg'
import Project_5_3 from '../../assets/images/projects/project-5-3.jpeg'

import Project_6_1 from '../../assets/images/projects/project-6-1.jpeg'
import Project_6_2 from '../../assets/images/projects/project-6-2.jpeg'

import Project_7_1 from '../../assets/images/projects/project-7-1.jpg'
import Project_7_2 from '../../assets/images/projects/project-7-2.jpg'
import Project_7_3 from '../../assets/images/projects/project-7-3.jpg'
import Project_7_4 from '../../assets/images/projects/project-7-4.jpeg'

import Project_8_1 from '../../assets/images/projects/project-8-1.jpeg'
import Project_8_2 from '../../assets/images/projects/project-8-2.jpeg'
import Project_8_3 from '../../assets/images/projects/project-8-3.jpeg'
import Project_8_4 from '../../assets/images/projects/project-8-3.jpeg'


import Project_9_1 from '../../assets/images/projects/project-9-1.jpeg'
import Project_9_2 from '../../assets/images/projects/project-9-2.jpeg'
import Project_9_3 from '../../assets/images/projects/project-9-3.jpeg'
import Project_9_4 from '../../assets/images/projects/project-9-4.jpeg'
import Project_9_5 from '../../assets/images/projects/project-9-5.jpeg'
import Project_9_6 from '../../assets/images/projects/project-9-6.jpeg'
import Project_9_7 from '../../assets/images/projects/project-9-7.jpeg'
import Project_9_8 from '../../assets/images/projects/project-9-7.jpeg'

import Project_10_1 from '../../assets/images/projects/project-10-1.jpg'
import Project_10_2 from '../../assets/images/projects/project-10-2.webp'
import Project_10_3 from '../../assets/images/projects/project-10-3.webp'
import Project_10_4 from '../../assets/images/projects/project-10-4.webp'

import Project_11_1 from '../../assets/images/projects/project-11-1.jpeg'
import Project_11_2 from '../../assets/images/projects/project-11-2.jpeg'
import Project_11_3 from '../../assets/images/projects/project-11-3.jpeg'
import Project_11_4 from '../../assets/images/projects/project-11-4.jpeg'
import Project_11_5 from '../../assets/images/projects/project-11-5.jpeg'

import Project_12_1 from '../../assets/images/projects/project-12-1.jpg'
import Project_12_2 from '../../assets/images/projects/project-12-2.jpg'
import Project_12_3 from '../../assets/images/projects/project-12-3.jpg'

import Project_13_1 from '../../assets/images/projects/project-13-1.jpg'
import Project_13_2 from '../../assets/images/projects/project-13-2.jpg'
import Project_13_3 from '../../assets/images/projects/project-13-3.jpg'
import Project_13_4 from '../../assets/images/projects/project-13-4.jpg'
import Project_13_5 from '../../assets/images/projects/project-13-5.jpg'


import Project_14_1 from '../../assets/images/projects/project-14-1.png'
import Project_14_2 from '../../assets/images/projects/project-14-2.png'
import Project_14_3 from '../../assets/images/projects/project-14-3.png'
import Project_14_4 from '../../assets/images/projects/project-14-4.jpeg'

import Project_15_1 from '../../assets/images/projects/project-15-1.jpeg'
import Project_15_2 from '../../assets/video/project-15.mp4'

import Project_16_1 from '../../assets/images/projects/project-16-1.jpg'
import Project_16_2 from '../../assets/images/projects/project-16-2.jpg'
import Project_16_3 from '../../assets/images/projects/project-16-3.jpg'
import Project_16_4 from '../../assets/images/projects/project-16-4.jpg'
import Project_16_5 from '../../assets/images/projects/project-16-5.jpg'


import Project_17_1 from '../../assets/images/projects/project-17-1.jpeg'
import Project_17_2 from '../../assets/images/projects/project-17-2.jpeg'
import Project_17_3 from '../../assets/images/projects/project-17-3.jpeg'

import Project_18_1 from '../../assets/images/projects/project-18-1.jpeg'
import Project_18_2 from '../../assets/images/projects/project-18-2.jpeg'
import Project_18_3 from '../../assets/images/projects/project-18-3.jpeg'
import Project_18_4 from '../../assets/images/projects/project-18-4.jpeg'


import Project_19_1 from '../../assets/images/projects/project-19-1.jpeg'

export const About = () => {

    const {t} = useTranslation()
    const [selectedWork, setSelectedWork] = useState<ICardWork | null>(null)
    const titleRef = useRef<HTMLHeadingElement>(null)
    const isVisibleTitle = useOnScreen(titleRef)
    const [settings] = useState({
        loop: true,
        spaceBetween: 15,
        slidesPerView: 2,
        freeMode: true,
        autoplay: {
            autoplay: {
                delay: 1,
                disableOnInteraction: false,
            },
            speed: 2000,
        },
        navigation: {
            nextEl: '.slidePrev-btn',
            prevEl: '.slideNext-btn'
        },
        breakpoints: {
            320: {
                spaceBetween: 12,
                slidesPerView: 1
            },
            1200: {
                spaceBetween: 12,
                slidesPerView: selectedWork?.images && selectedWork.images.length ? 2 : 1
            },
        },
    })

    const worksList: ICardWork[] = [
        {
            image: Project_16_1,
            title: 'project-title-16',
            description: 'project-description-16',
            images: [
                Project_16_1,
                Project_16_2,
                Project_16_3,
                Project_16_4,
                Project_16_5,
            ],

        },
        {
            image: Project_1_1,
            title: 'project-title-1',
            description: 'project-description-1',
            images: [
                Project_1_1,
                Project_1_2,
            ],

        },
        {
            image: Project_2_3,
            title: 'project-title-2',
            description: 'project-description-2',
            images: [
                Project_2_1,
                Project_2_2,
                Project_2_3,
            ],
        },
        {
            image: Project_3_1,
            title: 'project-title-3',
            description: 'project-description-3',
            images: [
                Project_3_1,
                Project_3_2,
            ],
        },
        {
            image: Project_4_2,
            title: 'project-title-4',
            description: 'project-description-4',
            images: [
                Project_4_1,
                Project_4_2,
                Project_4_3,
            ],
        },
        {
            image: Project_5_1,
            title: 'project-title-5',
            description: 'project-description-5',
            images: [
                Project_5_1,
                Project_5_2,
                Project_5_3,
            ],
        },
        {
            image: Project_6_1,
            title: 'project-title-6',
            description: 'project-description-6',
            images: [
                Project_6_1,
                Project_6_2,
            ],
        },
        {
            image: Project_7_2,
            title: 'project-title-7',
            description: 'project-description-7',
            images: [
                Project_7_2,
                Project_7_3,
                Project_7_4,
                Project_7_1,
            ],
        },
        {
            image: Project_8_1,
            title: 'project-title-8',
            description: 'project-description-8',
            images: [
                Project_8_1,
                Project_8_2,
                Project_8_3,
                Project_8_4,
            ],
        },
        {
            image: Project_9_1,
            title: 'project-title-9',
            description: 'project-description-9',
            images: [
                Project_9_1,
                Project_9_2,
                Project_9_3,
                Project_9_4,
                Project_9_5,
                Project_9_6,
                Project_9_7,
                Project_9_8,
            ],
        },
        {
            image: Project_10_1,
            title: 'project-title-10',
            description: 'project-description-10',
            images: [
                Project_10_1,
                Project_10_2,
                Project_10_3,
                Project_10_4,
            ],
        },
        {
            image: Project_11_1,
            title: 'project-title-11',
            description: 'project-description-11',
            images: [
                Project_11_1,
                Project_11_2,
                Project_11_3,
                Project_11_4,
                Project_11_5,
            ],
        },
        {
            image: Project_12_1,
            title: 'project-title-12',
            description: 'project-description-12',
            images: [
                Project_12_1,
                Project_12_2,
                Project_12_3,
            ],
        },
        {
            image: Project_13_1,
            title: 'project-title-13',
            description: 'project-description-13',
            images: [
                Project_13_1,
                Project_13_2,
                Project_13_3,
                Project_13_4,
                Project_13_5,
            ],
        },
        {
            image: Project_14_2,
            title: 'project-title-14',
            description: 'project-description-14',
            images: [
                Project_14_1,
                Project_14_2,
                Project_14_3,
                Project_14_4,
            ],
        }, {
            image: Project_15_1,
            title: 'project-title-15',
            description: 'project-description-15',
            video: Project_15_2,
            images: [
                Project_15_1,
            ],
        },
        {
            image: Project_17_1,
            title: 'project-title-17',
            description: 'project-description-17',
            images: [
                Project_17_1,
                Project_17_2,
                Project_17_3,
            ],
        },
        {
            image: Project_18_1,
            title: 'project-title-18',
            description: 'project-description-18',
            images: [
                Project_18_1,
                Project_18_2,
                Project_18_3,
                Project_18_4,
            ],
        }, {
            image: Project_19_1,
            title: 'project-title-19',
            description: 'project-description-19',
            images: [
                Project_19_1,
            ],
        },

    ]


    const handleClose = () => {
        setSelectedWork(null)
        document.body.classList.remove('G-hidden')
    }


    return <>
        <section className={`about-sec G-animation ${isVisibleTitle ? 'G-show-element' : ''}`} id='advantages'
                 ref={titleRef}>
            <div className="G-container">
                <h2>{t('about-title')}</h2>
                <div className="about-cnt G-flex">
                    <div className="about-works G-flex G-flex-wrap">
                        {worksList.map((item, index) => (
                            <CardWork
                                onClick={() => {
                                    setSelectedWork(item)
                                    document.body.classList.add('G-hidden')
                                }}
                                key={index}
                                image={item.image}
                                title={item.title}
                                description={item.description}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
        <div className={`card-information-content ${selectedWork ? 'active' : ''}`}>
            {selectedWork ? <>
                <div className='card-information-title G-align-start G-justify-between'>
                    <h3>{t(selectedWork?.title || '-')}</h3>
                    <div className='gallery-slider-navigations'>
                        <div className="next slideNext-btn">
                            <IconArrowLeft size={14}/>
                        </div>
                        <div className="prev slidePrev-btn">
                            <IconArrowRight size={14}/>
                        </div>
                    </div>
                </div>
                <div className='card-information-content-header'>
                    <Swiper modules={[Navigation, Autoplay]}  {...settings}
                            className="partners-slider swiper-container-free-mode">
                        {selectedWork?.images?.map((item, index) => {
                            return <SwiperSlide key={index}>
                                <div className="card-information-slider G-center">
                                    <img src={item} loading={"lazy"} alt="informations"/>
                                </div>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
                {selectedWork?.video ? <div className='card-information-video'>
                    {selectedWork?.video && <iframe
                        src={selectedWork?.video}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />}
                </div> : null}
                <div className='card-information-body'>
                    <p>{t(selectedWork?.description || '-')}</p>
                </div>
            </> : null}
        </div>
        <div onClick={handleClose} className={`card-information-bg ${selectedWork ? 'active' : ''}`}/>
    </>
}
